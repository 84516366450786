import { JsonProperty, Serializable } from 'typescript-json-serializer';

@Serializable()
export default class PipelineParameters {
  @JsonProperty('modelId')
  private readonly _modelId: string;
  @JsonProperty('pipelineTypes')
  private readonly _pipelineTypes: string[] | null;

  constructor(modelId: string, pipelineTypes: string[] | null) {
    this._modelId = modelId;
    this._pipelineTypes = pipelineTypes;
  }

  get modelId(): string {
    return this._modelId;
  }

  get pipelineTypes(): string[] | null {
    return this._pipelineTypes;
  }
}
