

























import Component, { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import { Prop } from 'vue-property-decorator';
import Model from '../../models/Model';
import PipelineService from '../../services/PipelineService';
import { PossibleAction } from '@/auth/PossibleAction';
import { hasPermission } from '@/auth/AuthService';
import PipelineParameters from '@/models/pipelines/PipelineParameters';

@Component
export default class RunPipelinesModal extends mixins(Toasts) {
  protected readonly PossibleAction = PossibleAction;

  @Prop({ required: true })
  protected projectId!: string;
  @Prop({ required: true })
  protected model!: Model;

  protected inProgress = false;
  protected types: string[] = [];
  protected selectedType: string | null = null;
  protected typeSelectorState: boolean | null = null;

  created(): void {
    this.loadPipelineTypes();
  }

  protected shouldBeVisible(action: PossibleAction): boolean {
    const permission = hasPermission(action);
    return permission != null && permission;
  }

  protected loadPipelineTypes(): void {
    PipelineService.listTypes().then((types) => {
      this.types = types;
    });
  }

  protected runPipeline(event: Event, model: Model): void {
    event.preventDefault();
    if (this.selectedType === null || this.selectedType.length === 0) {
      this.typeSelectorState = false;
      return;
    }
    this.typeSelectorState = true;
    if (hasPermission(PossibleAction.CAN_ANALYSE_MODEL) && model.id) {
      this.inProgress = true;
      const parameters = new PipelineParameters(model.id, [this.selectedType]);
      PipelineService.executePipelines(parameters)
        .then((createdPipelineExecution) => {
          this.$bvModal.hide('run-pipelines-modal');
          if (model.id != null) {
            this.$router.push({
              name: 'pipelineExecutionDetails',
              params: {
                projectId: this.projectId,
                modelId: model.id,
                executionId: createdPipelineExecution[0].executionId,
              },
            });
          }
        })
        .catch((backendError) => {
          this.showToast('Failed to execute', 'Oops, something failed: ' + backendError.response.status, 'danger');
        })
        .finally(() => (this.inProgress = false));
    } else {
      this.$bvModal.hide('run-pipelines-modal');
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected reset(): void {
    this.selectedType = null;
    this.typeSelectorState = null;
  }
}
